import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/romermaldonado/Practiceshop/aguaman/aguaman-landing-v4/src/components/BlogLayout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Expo Táchira 2022`}</h1>
    <p>{`La Expo Táchira es un evento organizado por `}<a href="https://instagram.com/milprovzla" target="_blank" style={{
        "color": "blue"
      }}>{`@milprovzla`}</a>{` que reúne a los principales comerciantes, industriales, emprendedores, agropecuarios, servicios y marcas del país (Venezuela). La #expotachira se celebró como parte de la Feria Internacional de San Sebastían en San Cristóbal, Estado Táchira entre el 20 y 30 de Enero del 2022.`}</p>
    <div className="div-mdx-center">
    <img src="https://s3.us-east-2.amazonaws.com/milprovzla.com/_next/static/images/logoexpo-8ced082649b6c5066fc0eccfb5ec2de3.png" alt="Logo Expo Táchira" />
    </div>
    <p>{`El stand de 3 metros de ancho por 3 metros de fondo lo decoramos con los colores y logos de la marca, pendones, botellones, dispensadores eléctricos y una moto con la cesta y su lona para que los asistentes tuvieran una noción de cómo funcionaba el servicio. La moto es el principal medio de transporte que usamos para entregar los botellones a nuestros clientes, la lona es esencial para `}<strong>{`proteger los botellones`}</strong>{` de la lluvia y del sol.`}</p>
    <div className="div-mdx-center">
    <img src="https://blog-aguaman.s3.amazonaws.com/photo_2022-02-02_19-46-05.jpg" alt="Foto de la moto del stand" />
    </div>
    <p>{`Las puertas al público se abrían a las 10 de la mañana y cerraban a las 10 de la noche. `}<strong>{`12 horas`}</strong>{` seguidas de atención al público, con gran asistencia de personas durante todo el día, fue una gran ventana de “exposición” para que los habitantes de la ciudad y sus alrededores conocieran a Aguaman.`}</p>
    <br />
    <h2>¿Qué queríamos conseguir con la participación en el evento?</h2>
    <p>{`Principalmente, queríamos dar a conocer el servicio a los asistentes y a los demás expositores, enseñarles y explicarles que existe un servicio en la ciudad que `}<strong>{`entrega botellones de agua a domicilio`}</strong>{`. La Expo Táchira se caracteriza por la venta de productos, servicios, comida, telefonía móvil, publicidad y mercadeo. Para tener algo “físico” que vender, incluimos dispensadores de agua eléctricos (que también se venden por la app).`}</p>
    <p>{`Esperábamos que las personas se interesaran por el servicio (botellones de agua a domicilio en 30 minutos) pero lo que llamó la atención fue el `}<strong>{`dispensador de agua eléctrico`}</strong>{`. Este aparato portátil dispensa el agua del botellón automáticamente al hundir un botón. El dispensador se vendió a 28.000 pesos colombianos ó 7.5 dólares americanos. Fue el pan caliente del stand, se vendieron más de `}<strong>{`250 dispensadores`}</strong>{` en todo el evento.`}</p>
    <div className="div-mdx-center">
    <img src="https://blog-aguaman.s3.amazonaws.com/photo_2022-02-02_19-46-08.jpg" alt="Foto del Stand" />
    </div>
    <p>{`Luego de tener la atención de las personas por la venta del dispensador, le enseñabamos que teniamos el servicio de botellones a domicilio y les dabamos un volante con la información de la página web, número de contacto y redes sociales. Muchos de ellos nos llamaron finalizado el evento para hacer uso del servicio.`}</p>
    <br />
    <h2>Lo que aprendimos</h2>
    <p>{`Para ser el primer año, lo calificamos `}<strong>{`positivo`}</strong>{` por el aprendizaje obtenido, más allá de las ventas y los nuevos clientes. Ver la reacción positiva de las personas por algo que has construido te llena de energías para seguir adelante, saber que estás construyendo algo que puede ayudar al `}<strong>{`bienestar y calidad de vida`}</strong>{` de las personas es gratificante.`}</p>
    <div className="div-mdx-center">
    <img src="https://blog-aguaman.s3.amazonaws.com/photo_2022-02-02_19-46-02.jpg" alt="Foto de parte del equipo de Aguaman" />
    </div>
    <br />
    <p>{`¿Te gustó este articulo? Comparte este artículo para que más personas conozcan de nuestro servicio y
podamos llegar a más lugares.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      